import React, { useEffect, useRef, useState } from 'react';
import { widget } from '../../charting_library';
import datafeed from './datafeed';
import { generateSymbol } from './helpers';
import { useSelector } from "react-redux";

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const TVChartMobileContainer = ({ currentTheme, currentResolution, setCurrentResolution, mode, chartReadyInfo, setChartReadyInfo }) => {
	const chartContainerRef = useRef();
	const tvWidgetRef = useRef(null);
	const selectedProduct = useSelector((state) => state.kprice.selectedProduct);
	const selectedChartResolution = useSelector((state) => state.kprice.selectedChartResolution);
	const productInfo = selectedProduct ? selectedProduct : "BTC-USDT";
	const [leftPairPart, rightPairPart] = productInfo.split('-');
    const symbol = generateSymbol('KPRICE', leftPairPart, rightPairPart);
	
	const defaultProps = {
		symbol: symbol.full,
		libraryPath: '/charting_library/',
		fullscreen: true,
		autosize: true,
		studiesOverrides: {},
	};

	useEffect(() => {
		setChartReadyInfo(false);

		const widgetOptions = {
			symbol: defaultProps.symbol,
			datafeed: datafeed,
			container: chartContainerRef.current,
			library_path: defaultProps.libraryPath,
			locale: getLanguageFromURL() || 'en',
			disabled_features: 
			mode == 1 ? ['use_localstorage_for_settings','header_symbol_search','symbol_search_hot_key','go_to_date','study_templates','left_toolbar','symbol_info','timeframes_toolbar','show_right_widgets_panel_by_default','popup_hints','control_bar','header_screenshot','display_market_status','context_menus','edit_buttons_in_legend','property_pages','header_widget']
			: ['use_localstorage_for_settings','header_symbol_search','symbol_search_hot_key','go_to_date','study_templates','symbol_info','timeframes_toolbar','show_right_widgets_panel_by_default','popup_hints','control_bar','header_screenshot','display_market_status','context_menus','edit_buttons_in_legend','property_pages'],
			enabled_features: ['adaptive_logo'],
			fullscreen: defaultProps.fullscreen,
			autosize: defaultProps.autosize,
			studies_overrides: defaultProps.studiesOverrides,
			custom_css_url: '../tvWidget.css',
			theme: currentTheme,
			interval: selectedChartResolution,
			header_widget_buttons_mode: 'compact',
			overrides: {
				'scalesProperties.fontSize': 10, 
				'paneProperties.legendProperties.showSeriesTitle': false,
				'paneProperties.legendProperties.showVolume': false,
				'paneProperties.backgroundType': 'solid',
				'paneProperties.background': currentTheme == "dark" ? '#212121' : '#FFFFFF',
			},
		};
		//left_toolbar disabled features
		//enabled features hide_left_toolbar_by_default,property_pages
		//charting-library-docs/v26/customization/Featuresets
		tvWidgetRef.current = new widget(widgetOptions);

		tvWidgetRef.current.onChartReady(() => {
		});

		tvWidgetRef.current.onChartReady(() => {
			setChartReadyInfo(true);

			if(mode == 2){
				tvWidgetRef.current.chart().onIntervalChanged().subscribe(null, (interval) => {
					setCurrentResolution(interval);
				});

				tvWidgetRef.current.headerReady().then(() => {
					const button = tvWidgetRef.current.createButton();
					button.setAttribute('title', 'Toggle Fullscreen');
					button.classList.add('apply-common-tooltip');
					button.style.position = "absolute";
					button.style.right = "0px";
					button.style.backgroundColor = currentTheme == "dark" ? '#1c1c1c' : '#fff';
					button.style.color = currentTheme == "dark" ? '#ddd' : '#333';
					button.style.zIndex = "999";
					button.style.padding = "0px";
					button.style.width = "38px";
					button.style.height = "45px";
					button.style.display = "flex";
					button.style.justifyContent = "center";
					button.style.alignItems = "center";
					button.style.cursor = "pointer";
					button.innerHTML = `
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-minimize-2">
						<polyline points="4 14 10 14 10 20"></polyline>
						<polyline points="20 10 14 10 14 4"></polyline>
						<polyline points="20 14 14 14 14 20"></polyline>
						<polyline points="4 10 10 10 10 4"></polyline>
					</svg>
					`;
					button.addEventListener('click', () => {
						if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
							window.ReactNativeWebView.postMessage('ExitFullSizeButtonClicked');
						}
					});
				});
			}
		});

		return () => {
			tvWidgetRef.current.remove();
		};
	}, [selectedProduct, mode]);

	useEffect(() => {
		if (tvWidgetRef.current && chartReadyInfo && mode == 1) {
			try {
				tvWidgetRef.current.chart().setResolution(currentResolution, () => {
				});
			} catch (error) {}
		}
	}, [currentResolution, chartReadyInfo, mode]);

	return (
		<div
			ref={chartContainerRef}
			className={'TVChartContainer'}
		/>
	);
}

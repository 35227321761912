import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Loading from "../components/Global/Loading";
import { toast } from 'react-toastify';
import axios from "axios";
import "./page_profile.css";
import userprofile from "../images/userprofile.png";
import SectionNotifications from "./section_notifications";
import VerificationKYC from "./VerificationKYC";
import VerificationSteps from "./VerificationSteps";
import SupportContent from "./SupportContent";
import ReactCountryFlag from "react-country-flag";

const Profile = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const userInfo = useSelector((state) => state.kprice.userInfo.data);
    const [customerId, setCustomerId] = useState(null);

    const contentForm = useRef(null);
    const [menuValue, setMenuValue] = useState("");
    const [referenceCodesLoading, setReferenceCodesLoading] = useState(true);
    const [referenceCodes, setReferenceCodes] = useState([]);
    const [referenceCodesResponse, setReferenceCodesResponse] = useState([]);

    const getReferenceCode = async () => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        try {
            const response = await axios.get(`https://api.kpricemarket.com/referencecode`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                }
            });

            if(response.status == 200){
                setReferenceCodesResponse(response.data);
                setReferenceCodesLoading(false);
            }
            else{
                toast.error("There was a problem loading reference codes!");
            }
        }
        catch (error) {
            toast.error("There was a problem loading reference codes!");
        }
    }

    const copyInfo = async (e) => {
        const info = e.target.innerHTML;
        try {
            await navigator.clipboard.writeText(info);
            toast.success("Copied successfully!");
        } catch (err) {
            toast.error("There was a problem copying the info!");
        }
    }

    const SuccessIcon = () => {
        return <div className="status-icon success"><i className="fa-solid fa-circle-check"></i></div>;
    }

    const WarningIcon = () => {
        return <Link to="/verification" className="status-icon warning" title="Please complete the required verification steps"><i className="fa-solid fa-circle-exclamation"></i></Link>;
    }

    const DangerIcon = () => {
        return <Link to="/profile/support" className="status-icon danger" title="Please contact our support team"><i className="fa-solid fa-circle-xmark"></i></Link>;
    }

    useEffect(() => {
        if(userInfo && userInfo.customer_id){
            setCustomerId(userInfo.customer_id);
        }
        else{
            setCustomerId(null);
        }
    }, [userInfo]);

    useEffect(() => {
        const codes = [];

        if(customerId){
            for (let i = 0; i < referenceCodesResponse.length; i++) {
                if(referenceCodesResponse[i].customer_id == customerId){
                    codes.push(referenceCodesResponse[i].reference_code);
                }
            }
        }

        setReferenceCodes(codes);
    }, [referenceCodesResponse, customerId]);

    useEffect(() => {//link değişirse
        if(location && location.pathname){
            if(location.pathname.includes("information")){
                setMenuValue("Information");
            }
            else if(location.pathname.includes("verification-steps")){
                setMenuValue("Verification Steps");
            }
            else if(location.pathname.includes("security")){
                setMenuValue("Security");
            }
            else if(location.pathname.includes("reference")){
                setMenuValue("Reference");
            }
            else if(location.pathname.includes("support")){
                setMenuValue("Support");
            }
            else if(location.pathname.includes("notifications")){
                setMenuValue("Notifications");
            }
            else if(location.pathname.includes("kyc-verification")){
                setMenuValue("KYC (Verification)");
            }
            else{
                setMenuValue("Information");
            }
        }
        else{
            setMenuValue("Information");
        }
    }, [location]);

    useEffect(() => {//menü değeri değişirse
        if(menuValue == "Information"){
            navigate("/profile/information");
        }
        else if(menuValue == "Verification Steps"){
            navigate("/profile/verification-steps");
        }
        else if(menuValue == "Security"){
            navigate("/profile/security");
        }
        else if(menuValue == "Reference"){
            navigate("/profile/reference");
        }
        else if(menuValue == "Support"){
            navigate("/profile/support");
        }
        else if(menuValue == "Notifications"){
            navigate("/profile/notifications");
        }
        else if(menuValue == "KYC (Verification)"){
            navigate("/profile/kyc-verification");
        }

        if(contentForm && contentForm.current){
            contentForm.current.style.animation = "none";
            let tempForAnimation = contentForm.current.offsetHeight;
            contentForm.current.style.animation = "open-animation 1.5s";
        }
    }, [menuValue]);

    useEffect(() => {
        getReferenceCode();
    }, []);
    
    return(
        <div id="main" className="main">
            <div className="section-profile">
                <div className="allsections allsections-border scrollbarhide menu">
                    <div className="user-img">
                        <div className={`image ${userInfo && userInfo.status_level && (userInfo.status_level == "2" ? "half-verify" : (userInfo.status_level == "3" ? "verify" : "not-verify"))}`} title={userInfo && userInfo.status_level ? (userInfo.status_level == "1" ? "To benefit from the features, please complete the verification steps" : (userInfo.status_level == "2" ? "To benefit from all features, please fill out the level 3 consent form" : "Level 3")) : "Not Verify"}>
                            {userInfo && userInfo.status_level && <span className="icondiv"></span>}
                            {userInfo && userInfo.status_level && ((userInfo.status_level == "2" || userInfo.status_level == "3") ? <i className="fa-solid fa-circle-check verify" title={userInfo.status_level == "2" ? "To benefit from all features, please fill out the level 3 consent form" : "Level 3"}></i> : <i className="fa-solid fa-circle-xmark not-verify" title={userInfo.status_level == "1" ? "To benefit from the features, please complete the verification steps" : "Not Verify"}></i>)}
                            <img src={userprofile} />
                        </div>
                        <div className="level">
                            <i className="fa-solid fa-crown"></i>
                            <label>Lvl {userInfo && userInfo.status_level ? userInfo.status_level : "1"}</label>
                        </div>
                        <div className="username">{userInfo && userInfo.customer_id ? userInfo.customer_id : ""}</div>
                    </div>
                    <Link to="/profile/information" className={menuValue == "Information" ? "list-element active" : "list-element"}>Information</Link>
                    <Link to="/profile/kyc-verification" className={menuValue == "KYC (Verification)" ? "list-element active" : "list-element"}>KYC (Verification)</Link>
                    <Link to="/profile/verification-steps" className={menuValue == "Verification Steps" ? "list-element active" : "list-element"}>Verification Steps</Link>
                    <Link to="/profile/security" className={menuValue == "Security" ? "list-element active" : "list-element"}>Security</Link>
                    <Link to="/profile/reference" className={menuValue == "Reference" ? "list-element active" : "list-element"}>Reference</Link>
                    <Link to="/profile/support" className={menuValue == "Support" ? "list-element active" : "list-element"}>Support</Link>
                    <Link to="/profile/notifications" className={menuValue == "Notifications" ? "list-element active" : "list-element"}>Notifications</Link>
                </div>
                <div className="allsections allsections-border scrollbarhide content">
                    <div className="inner-content" ref={contentForm}>
                        {menuValue != "Notifications" && <div className="content-title">{menuValue}</div>}
                        {menuValue == "Information" && <>
                            <div className="informations">
                                <div className="item">
                                    <label>Id:</label>
                                    <span>{userInfo && userInfo.customer_id ? userInfo.customer_id : ""}</span>
                                </div>
                                <div className="item">
                                    <label>Level:</label>
                                    <span>{userInfo && userInfo.status_level ? userInfo.status_level : "1"}</span>
                                </div>
                                <div className="item">
                                    <label>Full Name:</label>
                                    <span>{userInfo && userInfo.full_name ? userInfo.full_name : ""}</span>
                                </div>
                                <div className="item">
                                    <label>Email:</label>
                                    <span>{userInfo && userInfo.masked_email ? userInfo.masked_email : ""}</span>
                                </div>
                                <div className="item">
                                    <label>Phone:</label>
                                    <span>{userInfo && userInfo.phone_verification && userInfo.phone_verification == "approved" ? <SuccessIcon /> : <WarningIcon />}{userInfo && userInfo.masked_phone_number ? userInfo.masked_phone_number : ""}</span>
                                </div>
                                <div className="item">
                                    <label>Nationality:</label>
                                    <span>{userInfo && userInfo.nationality ? <ReactCountryFlag countryCode={userInfo.nationality} svg title={userInfo.nationality} /> : <WarningIcon />}</span>
                                </div>
                                <div className="item br">
                                    <label>Spot Transaction Permission:</label>
                                    <span>{userInfo && userInfo.spot_permission && userInfo.spot_permission == "True" ? <SuccessIcon /> : <WarningIcon />}</span>
                                </div>
                                <div className="item">
                                    <label>Future Transaction Permission:</label>
                                    <span>{userInfo && userInfo.future_permission && userInfo.future_permission == "True" ? <SuccessIcon /> : <WarningIcon />}</span>
                                </div>
                                <div className="item br">
                                    <label>Deposit:</label>
                                    <span>{userInfo && userInfo.deposit_permission && userInfo.deposit_permission == "True" ? <SuccessIcon /> : <WarningIcon />}</span>
                                </div>
                                <div className="item">
                                    <label>Withdraw:</label>
                                    <span>{userInfo && userInfo.withdraw_permission && userInfo.withdraw_permission == "True" ? <SuccessIcon /> : <WarningIcon />}</span>
                                </div>
                                <div className="item br">
                                    <label>Account Status:</label>
                                    <span>{userInfo && userInfo.status && userInfo.status == "active" ? <SuccessIcon /> : <DangerIcon />}</span>
                                </div>
                            </div>
                        </>}
                        {menuValue == "KYC (Verification)" && <>
                            <VerificationKYC />
                        </>}
                        {menuValue == "Verification Steps" && <>
                            <div className="section-verification">
                                <VerificationSteps pageType="readonly" />
                            </div>
                        </>}
                        {menuValue == "Security" && <>
                            <Link to="/profile/security/change-phone" className="button"><i className="fa-solid fa-phone"></i>Change Phone</Link>
                            <Link to="/change-password" className="button" style={{margin: '15px 0px'}}><i className="fa-solid fa-key"></i>Change Password</Link>
                            <Link to="/profile/security/delete-account" className="button"><i className="fa-solid fa-user-xmark"></i>Delete Account</Link>
                        </>}
                        {menuValue == "Reference" && <>
                        {referenceCodesLoading || customerId == null ? <Loading />
                            : (referenceCodes.length > 0 ? 
                            <> 
                                <div className="reference-code-title">
                                    <label>Code</label>
                                    <label>Link</label>
                                </div>
                                <div className="reference-code-info">
                                    {referenceCodes.map((code, index) => (
                                        <div key={index} className="item">
                                            <label onClick={(e) => copyInfo(e)} title="Click to copy">{code}</label>
                                            <label onClick={(e) => copyInfo(e)} title="Click to copy">{`${window.location.origin}/register?reference=${code}`}</label>
                                        </div>
                                    ))}
                                </div>
                            </>
                            : 
                            <div className="no-reference-code">
                                There is no reference code
                            </div>)}
                        </>}
                        {menuValue == "Support" && <>
                            <SupportContent type="section" />
                        </>}
                        {menuValue == "Notifications" && <>
                            <div className="page-notifications">
                                <SectionNotifications />
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Profile;
import React, { useEffect, useRef, useState } from 'react';
import { widget } from '../../charting_library';
import datafeed from './datafeed';
import { generateSymbol } from './helpers';
import { useDispatch, useSelector } from "react-redux";
import { onChangeChartResolution } from "../../Reducer/kpriceReducer";

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const TVChartContainer = ({ chartReady, setChartReady, viewMode }) => {
    const dispatch = useDispatch();
	const chartContainerRef = useRef();
	const tvWidgetRef = useRef(null);
	const selectedProduct = useSelector((state) => state.kprice.selectedProduct);
	const selectedChartResolution = useSelector((state) => state.kprice.selectedChartResolution);
	const productInfo = selectedProduct ? selectedProduct : "BTC-USDT";
	const [leftPairPart, rightPairPart] = productInfo.split('-');
    const symbol = generateSymbol('KPRICE', leftPairPart, rightPairPart);

	const onResolutionChange = (newResolution) => {
		dispatch(onChangeChartResolution({ newResolution }));
	};
	
	const defaultProps = {
		symbol: symbol.full,
		libraryPath: '/charting_library/',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
	};

	useEffect(() => {
		setChartReady(false);

		const adjustWidgetSize = () => {
			const chartContainer = chartContainerRef.current;
			
			if(chartContainer) {
				chartContainer.style.width = '100%'; // Genişliği her zaman %100 olarak ayarla
				chartContainer.style.height = window.innerWidth <= 650 ?  '400px' : '586px'; // Mobil için 300px, masaüstü için 500px
			}
		};

		const widgetOptions = {
			symbol: defaultProps.symbol,
			datafeed: datafeed,
			container: chartContainerRef.current,
			library_path: defaultProps.libraryPath,
			locale: getLanguageFromURL() || 'en',
			disabled_features: 
			viewMode == 1 ? ['use_localstorage_for_settings','header_symbol_search','symbol_search_hot_key','go_to_date','study_templates','display_market_status','context_menus']
			: ['use_localstorage_for_settings','header_symbol_search','symbol_search_hot_key','go_to_date','study_templates','display_market_status','context_menus'],
			enabled_features: ['header_in_fullscreen_mode'],
			fullscreen: defaultProps.fullscreen,
			autosize: defaultProps.autosize,
			studies_overrides: defaultProps.studiesOverrides,
			custom_css_url: '../tvWidget.css',
			theme: document.body.classList.contains("dark") ? 'dark' : 'light',
			interval: selectedChartResolution,
			overrides: {
				'paneProperties.backgroundType': 'solid',
				'paneProperties.background': document.body.classList.contains("dark") ? '#212121' : '#FFFFFF',
			},
		};

		tvWidgetRef.current = new widget(widgetOptions);

		tvWidgetRef.current.onChartReady(() => {
			setChartReady(true);
			tvWidgetRef.current.chart().onIntervalChanged().subscribe(null, (interval) => {
				onResolutionChange(interval);
			});

			tvWidgetRef.current.headerReady().then(() => {
				const currentTheme = document.body.classList.contains("dark") ? "dark" : "light";
				const button = tvWidgetRef.current.createButton();
				button.setAttribute('title', 'Exit Full Screen');
				button.classList.add('apply-common-tooltip');
				button.style.position = "absolute";
				button.style.right = "38px";
				button.style.backgroundColor = "transparent";
				button.style.color = "transparent";
				button.style.zIndex = "999";
				button.style.padding = "0px";
				button.style.width = "40px";
				button.style.height = "55px";
				button.style.display = "none";
				button.style.justifyContent = "center";
				button.style.alignItems = "center";
				button.style.cursor = "default";
				button.innerHTML = `
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-minimize-2">
						<polyline points="4 14 10 14 10 20"></polyline>
						<polyline points="20 10 14 10 14 4"></polyline>
						<polyline points="20 14 14 14 14 20"></polyline>
						<polyline points="4 10 10 10 10 4"></polyline>
					</svg>
				`;
		
				button.addEventListener('click', () => {
					if (document.fullscreenElement) {
						document.exitFullscreen();
					}
				});
		
				//tam ekran modunu dinle
				document.addEventListener('fullscreenchange', () => {
					if (document.fullscreenElement) {
						button.style.display = "flex";//tam ekran modunda butonu göster
					} else {
						button.style.display = "none";//tam ekran modunda butonu gizle
					}
				});
			});

			adjustWidgetSize();
		});

		window.addEventListener('resize', adjustWidgetSize);

		return () => {
			//tvWidgetRef.current.chart().onIntervalChanged().unsubscribe(null, onResolutionChange);
			window.removeEventListener('resize', adjustWidgetSize);
			tvWidgetRef.current.remove();
		};
	}, [selectedProduct]);

	useEffect(() => {//observer
		// Observer'ın callback fonksiyonu
        const callback = (mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
					if(chartReady && tvWidgetRef && tvWidgetRef.current && typeof tvWidgetRef.current.chart == 'function' && typeof tvWidgetRef.current.changeTheme == 'function'){
						const newTheme = document.body.classList.contains("dark") ? "dark" : "light";
						tvWidgetRef.current.changeTheme(newTheme);
						
						setTimeout(() => {
							try {
								const newBackground = newTheme === "dark" ? '#212121' : '#FFFFFF';
								tvWidgetRef.current.chart().applyOverrides({ 'paneProperties.background': newBackground, 'paneProperties.backgroundType': 'solid'});
							} catch (error) {}
						}, 1);
					}
                }
            }
        };
		
        // MutationObserver'ı başlatma
        const observer = new MutationObserver(callback);
    
        // `body` elementini ve hangi değişiklikleri dinlemek istediğimizi tanımlama
        observer.observe(document.body, { attributes: true });

		return () => {
			observer.disconnect();
		};
	}, [chartReady]);

	return (
		<div
			ref={chartContainerRef}
			className={'TVChartContainer'}
		/>
	);
}
